<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Atelier réflexologie bébé émotionnelle®</h1>
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    La réflexologie bébé émotionnelle® est destinée à soulager
                    les maux du quotidien et apaiser les émotions du bébé et du
                    jeune enfant en libérant les blocages émotionnels liés à la
                    vie intra utérine, la naissance et l'histoire familiale de
                    l'enfant.<br />
                    <br />
                    Cette méthode permet également de retravailler une naissance
                    qui aurait pu être difficile ou tout simplement de souhaiter
                    la bienvenue à son bébé avec des protocoles et une
                    verbalisation très spécifique.<br />
                    <br />
                    Lors de cet atelier, je vous transmets sur mon poupon les
                    techniques afin de soulager votre bébé. Vous allez apprendre
                    les gestes de réflexologie et leurs bienfaits pour les
                    reproduire sur votre bébé. C’est l’occasion de prendre du
                    temps pour vous et votre bébé. Un instant de bien être, une
                    bulle de douceur qui permet de communiquer au-delà des
                    mots…<br />
                    <br />
                    <price :price="60" :group="true" />
                </p>
            </b-col>
            <b-col xs="12" lg="6" style="margin-top: -20px">
                <non-clickable-img url="reflexo.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Reflexology',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
h1 {
    line-height: 150%;
}

p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
